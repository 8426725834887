import { Column } from '../../../types';

interface DisplayInfo {
	displayCategory: boolean;
	displayGroup: boolean;
	displayTeam: boolean;
}

export const getUserColumns = (toDisplay: DisplayInfo): Column[] => {
	const { displayCategory, displayGroup, displayTeam } = toDisplay;

	const columns: Column[] = [
		{ id: 'name' as const, name: 'ФИО', width: 16.25 },
		{ id: 'days' as const, name: 'Дни', width: 4.75 },
	];

	if (displayCategory) {
		columns.unshift({ id: 'category' as const, name: 'Категория', width: 6.375 });
	}

	if (displayGroup) {
		const nameIndex = columns.findIndex(column => column.id === 'name');
		columns.splice(nameIndex + 1, 0, { id: 'group' as const, name: 'Группа', width: 4.9375 });
	}

	if (displayTeam) {
		const daysIndex = columns.findIndex(column => column.id === 'days');
		columns.splice(daysIndex, 0, { id: 'team' as const, name: 'Команда', width: 9.875 });
	}

	return columns;
};
