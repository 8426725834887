import React, { DetailedHTMLProps, HTMLAttributes, ReactNode, useMemo, useState } from 'react';
import { ListColumns } from '../LIstColumns/ui/ListColumns';
import { ListCard } from '../ListCard/ListCard';
import s from './CardsList.module.scss';

interface ColumnTitle {
	title: string;
	width: number; // In percent
}

interface ColumnContent {
	Value: ReactNode;
	width: number;
}

interface Card extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	id: string;
	columns: ColumnContent[];
	leftComponent?: {
		width: number;
		Component: ReactNode;
	};
	rightComponent?: {
		width: number;
		Component: ReactNode;
	};
}

interface Props {
	cards: Card[];
	isWideCard?: boolean;
	columns: ColumnTitle[];
}

export const CardsList: React.FC<Props> = props => {
	const {
		cards, //
		isWideCard,
		columns,
	} = props;

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	const sortedCards = useMemo(() => {
		return isAscendingSort ? cards : [...cards].reverse();
	}, [cards, isAscendingSort]);

	const margins: [number, number] = [(cards[0] && cards[0].leftComponent?.width) ?? 0, (cards[0] && cards[0].rightComponent?.width) ?? 0];

	// * Render
	return (
		<>
			<ListColumns
				margins={margins}
				columns={columns}
				isAscendingSort={isAscendingSort}
				handleSortChange={handleSortChange}
			/>
			<div className={s.cards}>
				{sortedCards.map(card => (
					<ListCard
						key={card.id}
						leftComponent={card.leftComponent}
						rightComponent={card.rightComponent}
						columns={card.columns}
						style={isWideCard ? { ...card?.style, padding: '.5rem 2rem' } : { ...card?.style }}
					/>
				))}
			</div>
		</>
	);
};
