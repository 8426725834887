import { FC } from 'react';
import { routerPath } from 'src/app/router/paths';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg_icons/action/close.svg';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import s from './UsersList.module.scss';
import { COLUMNS } from './consts';
import { UserListItem } from './types';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';

interface Props {
	users: UserListItem[];
	onRemoveUser: (userId: string) => void;
	isLoading?: boolean;
}

export const UsersList: FC<Props> = props => {
	const {
		users, //
		onRemoveUser,
		isLoading,
	} = props;

	const onUserClick = (userId: string) => {
		const userUrl = `${routerPath.users.page}/${userId}`;
		window.open(userUrl, '_blank', 'noopener,noreferrer');
	};

	const tableData = users.map(user => {
		return {
			id: user.id,
			avatar: (
				<Avatar
					userAvatar={user.avatar}
					name={user.name}
				/>
			),
			name: user.name,
			department: user.department ?? '',
			position: user.position ?? '',
			extra: (
				<>
					{isLoading ? (
						<LoaderCircle />
					) : (
						<CloseSVG
							className={s.removeButton}
							onClick={event => {
								event.stopPropagation();
								onRemoveUser(user.id || '');
							}}
						/>
					)}
				</>
			),
		};
	});

	// * Render
	return (
		<>
			{users.length > 0 && (
				<CardsTable
					data={tableData}
					columns={COLUMNS}
					onRowClick={rowId => onUserClick(tableData[rowId].id)}
					sortingColumnId="name"
				/>
			)}
		</>
	);
};
