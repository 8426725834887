import React from 'react';
import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { AddUsersMC } from 'src/features/_modal_contents/AddUsersMC';

interface Props {
	toggleModal: () => void;
}

export const AddUserMCContainer: React.FC<Props> = props => {
	const {
		toggleModal, //
	} = props;

	// * Selectors
	const companyUsers = useAppSelector(state => state.user_service.user.companyUsers);
	const officeId = useAppSelector(state => state.office_service.office.office?.id);

	// * Actions
	const dispatch = useAppDispatch();
	const { getOffice } = officeServiceActions.office.async;
	const { createOfficeUsers } = officeServiceActions.user.async;

	// * API
	const { data: usersListData, isLoading: isLoadingUsersList } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 2000,
		includepositions: true,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
		isascendingsort: true,
	});

	const usersList = usersListData?.body ?? [];

	const { data: departmentData, isLoading: isLoadingDepartments } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const allDepartments = departmentData?.body ?? [];

	const isLoading = isLoadingUsersList || isLoadingDepartments;

	// * Company users id
	const companyUsersIds =
		companyUsers?.map(companyUser => {
			const { body } = companyUser;
			const { user } = body;
			return user.id;
		}) || [];

	// * All users
	const allUsers =
		usersList.map(user => {
			return {
				id: user.user.id,
				name: `${user.user.lastName} ${user.user.firstName}`,
				position: user.positionUser?.name ?? '',
				department: user.departmentUser ? user.departmentUser.department.name : '',
				avatar: user.user?.avatar,
			};
		}) || [];

	const nonOfficeUsers = allUsers?.filter(item => {
		if (companyUsersIds?.find(id => id === item.id)) {
			return;
		}
		return item;
	});

	const onAddUser = (usersIds: string[]) => {
		officeId &&
			dispatch(
				createOfficeUsers({
					payload: {
						officeId,
						userIds: [...usersIds],
					},
				}),
			)
				.unwrap()
				.then(() => {
					dispatch(getOffice({ params: { officeId } }));
					toggleModal();
				})
				.catch(error => console.log(error));
	};

	// * Render
	return (
		<AddUsersMC
			title="Добавление сотрудника"
			allUsers={nonOfficeUsers}
			allDepartments={allDepartments}
			setSelectedUsers={onAddUser}
			isLoading={isLoading}
			toggleModal={toggleModal}
		/>
	);
};
