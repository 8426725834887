import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetUserFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { useFindPositionsQuery } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { PositionInfo } from 'src/app/redux/queries/position-service/types/types';
import { SelectStringSearchFilter } from 'src/entities/_filters/SelectStringSearchFilter/SelectStringSearchFilter';
import { useRights } from 'src/shared/hooks/useRights';
import { departmentRoleToRussian } from 'src/shared/lib/string';
import { ConditionalRendering } from 'src/shared/providers';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { RoleStar } from 'src/shared/ui/RoleStar/RoleStar';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { AddTeamMember } from '../../../AddTeamMember/ui/AddTeamMember/AddTeamMember';
import { COLUMNS, TableDataItem } from '../../const';
import { getRoleOrder } from '../../lib/getRoleOrder/getRoleOrder';
import { RoleAssignButton } from '../RoleAssignButton/RoleAssignButton';
import s from './Team.module.scss';

interface Props {
	departmentId: string;
	isActive: boolean;
}

export const Team: FC<Props> = props => {
	const { departmentId, isActive } = props;

	// * Navigation
	const navigate = useNavigate();

	// * Rights
	const departmentRightsOrAdmin = useRights(4);

	// * Api - 1/2
	const { data: dataPositions } = useFindPositionsQuery({
		takecount: 1000,
		skipcount: 0,
		includeDeactivated: false,
	});

	const positions = dataPositions?.body;

	// * Filters
	let positionOptions: Array<Omit<PositionInfo, 'id' | 'description' | 'isActive'> & { id: string | null }> = [];

	if (positions) {
		positionOptions = positionOptions.concat(
			positions?.map(position => ({
				id: position.id,
				name: position.name,
			})),
		);
	}

	const [filterPosition, setFilterPosition] = useState<(typeof positionOptions)[number]>({
		id: null,
		name: '',
	});

	const [filterName, setFilterName] = useState<string>('');

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	// * Api - 2/2
	const { data: dataUsers, isLoading } = useGetUserFindQuery({
		takecount: 1000,
		skipcount: 0,
		departmentId,
		// departmentUserRoleAscendingSort: false,
		includePositions: true,
		isActive: true,
		byPositionId: filterPosition.id === null ? undefined : filterPosition.id,
		fullnameincludesubstring: filterName !== '' ? filterName : undefined,
		isAscendingSort,
	});

	const users = dataUsers?.body ?? [];
	const sortedUsers = [...users]
		// .sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
		.sort((a, b) => getRoleOrder(a) - getRoleOrder(b));

	// * Team table data
	const tableData: TableDataItem[] = sortedUsers.map(user => {
		const { id, firstName, lastName, middleName, position, departmentUser, imageId } = user;
		const fullName = `${lastName || ''} ${firstName || ''} ${middleName || ''}`.trim();
		const positionName = position?.name ?? '';
		const role = departmentUser?.role ?? 'Employee';
		const assignment = departmentUser?.assignment ?? 'Employee';

		return {
			avatar: (
				<Link
					className={s.user_link}
					to={`/users/${id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Avatar
						className={s.avatar}
						name={fullName}
						avatarId={imageId}
					/>
				</Link>
			),
			fullName_position: (
				<>
					<Link
						className={s.fullName}
						to={`/users/${id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{fullName}
						<RoleStar
							role={role}
							assignment={assignment}
						/>
					</Link>

					<div className={s.positionName}>{positionName}</div>
				</>
			),
			role: departmentRoleToRussian(role, assignment), // ? Не понятно нужно ли тут фигачить assignment если там Director
			extra: (
				<>
					{departmentRightsOrAdmin && (
						<RoleAssignButton
							departmentId={departmentId}
							userId={id}
							assignment={assignment}
							role={role}
						/>
					)}
				</>
			),
		};
	});

	// * Render
	return (
		<>
			<SelectStringSearchFilter
				className={s.filters}
				selectValue={filterPosition}
				setSelect={setFilterPosition as (value: { id: null | string; name: string | null }) => void}
				selectPlaceholder="Должность"
				selectOptions={positionOptions}
				searchStringValue={filterName}
				setSearchString={setFilterName}
				searchPlaceholder="Поиск по ФИО"
			/>

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!sortedUsers}
				LoadedResult={
					<>
						{departmentRightsOrAdmin && isActive && (
							<AddTeamMember
								departmentId={departmentId}
								departmentUsersIds={sortedUsers.map(user => user.id)}
							/>
						)}

						{sortedUsers && sortedUsers.length > 0 && (
							<CardsTable
								data={tableData}
								columns={COLUMNS}
								sortingColumnId="fullName_position"
								isAscendingSort={isAscendingSort}
								handleSortChange={handleSortChange}
							/>
						)}
					</>
				}
			/>
		</>
	);
};
