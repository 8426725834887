import cn from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as DocsSVG } from 'src/shared/assets/svg/files/folder.svg';
import { ReactComponent as MediaSVG } from 'src/shared/assets/svg/files/image.svg';
import s from './BugReportDropZone.module.scss';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { Button } from 'src/shared/ui/_buttons/Button';

interface Props extends ComponentPropsWithoutRef<'div'> {
	onDrop: (acceptedFiles: any) => Promise<void>;
	accept?: { [key: string]: string[] }; // Mime types. Пример: {'image/png': ['.png'], 'text/html': ['.html', '.htm']}
	iconType?: 'media' | 'docs';
	isLoading?: boolean;
	placeholder?: string;
	maxSize?: number;
	maxFiles?: number;
}

// TODO: Возможно стоит доработать или убрать лишние пропсы
export const BugReportDropZone: React.FC<Props> = props => {
	const {
		onDrop,
		accept,
		iconType,
		isLoading,
		placeholder,

		maxSize = 30000000, // 30mb,
		maxFiles = 2,
		className,
	} = props;

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		maxSize,
		maxFiles,
		accept,
		noClick: true,
	});

	const getMaxMbString = () => {
		return maxSize / 1000000;
	};

	const acceptedFilesString =
		accept &&
		Object.values(accept)
			.flat()
			.map(accept => accept.slice(1).toUpperCase())
			.join(', ');

	const getIcon = () => {
		if (iconType === 'media') {
			return <MediaSVG />;
		} else if (iconType === 'docs') {
			return <DocsSVG />;
		} else {
			return null;
		}
	};

	// * Paste from clipboard
	const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		const items = event.clipboardData.items;

		let file: File | null = null;

		for (let i = 0; i < items.length; i++) {
			if (items[i].type.indexOf('image') === 0) {
				const pastedFile = items[i].getAsFile();
				file = pastedFile && new File([pastedFile], `screenshot-${new Date().toLocaleString()}.png`, { type: pastedFile.type });
			}
		}

		file && onDrop([file]);
	};

	// * Paste after button click
	const onButtonClick = async () => {
		const clipboardContents = await navigator.clipboard.read();

		for (const clipboardItem of clipboardContents) {
			for (const type of clipboardItem.types) {
				if (type.includes('image')) {
					const blob = await clipboardItem.getType(type);
					onDrop([blob]);
				}
			}
		}
	};

	// * Render
	return (
		<div
			className={cn(s.container, className)}
			onPaste={onPaste}
			{...getRootProps()}
		>
			<input {...getInputProps()} />

			<div className={s.text_wrapper}>
				{isLoading ? (
					<LoaderCircle />
				) : (
					<>
						{getIcon()}

						{isDragActive ? <p className={s.text_main}>Сбросьте файлы сюда...</p> : <p className={s.text_main}>{placeholder || 'Перетащите файлы в эту область или нажмите кнопку'}</p>}

						{acceptedFilesString && <p className={s.text_comment}>Поддерживаемые форматы: {acceptedFilesString}</p>}

						<p className={s.text_comment}>Максимальный размер файла: {getMaxMbString()} МБ</p>

						<div className={s.buttons}>
							<Button
								type="button"
								variant="secondary"
								fixedWidth
								onClick={onButtonClick}
							>
								Вставить из буфера
							</Button>

							<Button
								fixedWidth
								onClick={e => {
									e.preventDefault();
									open();
								}}
							>
								Выбрать файлы
							</Button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
