import { BaseQueryFn, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';
import { ColumnDef, Row, flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { GetStatFindApiArg } from 'src/app/redux/queries/time-service/types/regTypes';
import { UserStatInfoFindResultResponse } from 'src/app/redux/queries/time-service/types/resTypes';
import { ReactComponent as DownArrowSVG } from 'src/shared/assets/svg_icons/arrows/down_2.svg';
import { ReactComponent as UpArrowSVG } from 'src/shared/assets/svg_icons/arrows/up_2.svg';
import { TableWithComments } from 'src/shared/ui/_tables/TableWithComments';
import { AddLeaveButton } from '../AddLeaveButton/AddLeaveButton';
import s from './TimeListTable.module.scss';
import { COLUMNS_TIMELIST, TimeListTableDataItem } from './consts/COLUMNS_TIMELIST';
import { generateColumnsLeaveInfo } from './lib/generateColumnsLeaveInfo';
import { generateColumnsProjectsInfo } from './lib/generateColumnsProjectsInfo';

interface Props {
	users: TimeListTableDataItem[];
	refetch: () => QueryActionCreatorResult<
		QueryDefinition<
			GetStatFindApiArg,
			BaseQueryFn,
			'Import' | 'LeaveTime' | 'Stat' | 'WorkTime' | 'WorkTimeDayJob' | 'WorkTimeMonthLimit',
			UserStatInfoFindResultResponse,
			'timeservice'
		>
	>;
}

export const TimeListTable: React.FC<Props> = props => {
	const {
		users, //
		refetch,
	} = props;

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	const sortedUsers = useMemo(() => {
		return isAscendingSort ? users : [...users].reverse();
	}, [users, isAscendingSort]);

	// * Table
	const table = useReactTable({
		data: sortedUsers,
		columns: COLUMNS_TIMELIST,
		getRowCanExpand: () => true,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		autoResetExpanded: false,
	});

	// * Render
	return (
		<div className={s.container}>
			<div className={s.table}>
				{table.getHeaderGroups().map(headerGroup => (
					<div
						key={headerGroup.id}
						className={cn(s.table_row, s.header_wrapper)}
					>
						{headerGroup.headers.map(header => (
							<div
								key={header.id}
								style={header.column.columnDef.meta?.style}
								className={cn(s.table_header, s.sort_wrapper)}
								onClick={header.id === 'fullName' ? handleSortChange : undefined}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								{header.id === 'fullName' && (isAscendingSort ? <UpArrowSVG /> : <DownArrowSVG />)}
							</div>
						))}
					</div>
				))}

				{table.getRowModel().rows.map(row => (
					<div
						key={row.id}
						className={s.row_wrapper}
					>
						<div
							className={cn(s.table_row, row.getIsExpanded() ? s.row_card_selected : s.row_card, !row.original.isActive && s.row_card_disabled)}
							onClick={row.original.isActive ? row.getToggleExpandedHandler() : undefined}
						>
							{row.getVisibleCells().map(cell => (
								<div
									style={cell.column.columnDef.meta?.style}
									key={cell.id}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</div>
							))}
						</div>

						{row.getIsExpanded() && (
							<div>
								{renderSubProjects(row, generateColumnsProjectsInfo(refetch))}
								{renderSubLeave(row, generateColumnsLeaveInfo(refetch), refetch)}
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

const renderSubProjects = (row: Row<TimeListTableDataItem>, subColumns: ColumnDef<any, any>[]) => {
	const { projects } = row.original;

	return (
		<div className={s.sub_container}>
			<h4>Детализация по проектам</h4>

			{projects.length > 0 ? (
				<TableWithComments
					columns={subColumns}
					data={projects}
				/>
			) : (
				<div className={s.empty_block}>Нет данных о проектах</div>
			)}
		</div>
	);
};

const renderSubLeave = (row: Row<TimeListTableDataItem>, subColumns: ColumnDef<any, any>[], refetch: () => void) => {
	const { id, leave } = row.original;

	return (
		<div className={s.sub_container}>
			<h4>
				Детализация по отсутствиям
				<AddLeaveButton
					userId={id}
					refetch={refetch}
				/>
			</h4>

			{leave.length > 0 ? (
				<TableWithComments
					columns={subColumns}
					data={leave}
				/>
			) : (
				<div className={s.empty_block}>Нет данных об отсутствиях</div>
			)}
		</div>
	);
};
