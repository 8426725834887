import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetGroupsByGroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { routerPath } from 'src/app/router/paths';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DatePickerTitleButton } from 'src/shared/ui/_inputs/date_pickers/DatePickerTitleButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { PlannedVacationMC } from '../_modals/PlannedVacationMC';
import { SendMailModal } from '../_modals/SendMailModal';
import s from './SubHeader.module.scss';
import { useAppSelector } from 'src/app/redux/utils';
import { useGroupUserContext } from '../../context/useGroupUsersContext';

export const SubHeader = () => {
	// * Navigation
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const groupId = searchParams.get('group');
	const year = searchParams.get('year');

	const [isFirstClick, setIsFirstClick] = useState(true);

	useEffect(() => {
		if (!isFirstClick) localStorage.setItem('vacationYear', String(year));
	}, [year]);

	const setYear = (year: number) => {
		setSearchParams(prev => {
			return {
				...Object.fromEntries(prev.entries()),
				year: String(year),
			};
		});
		if (isFirstClick) setIsFirstClick(false);
	};

	// * Context
	const { setActiveUserId } = useGroupUserContext();

	// * API
	const { data } = useGetGroupsByGroupIdQuery(
		{
			groupId: groupId ?? '',
		},
		{
			skip: groupId === 'null',
		},
	);

	const groupInfo = data?.body;

	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * Year selection
	const selectedDate = year ? new Date(year) : undefined;
	const setSelectedDate = (date: Date) => setYear(date.getFullYear());

	// * Modals
	const [modalSendEmail, setModalSendEmail] = useState<boolean>(false);
	const toggleModalSendEmail = () => setModalSendEmail(prevState => !prevState);

	// * Planned vacation modal
	const openModal = () => userId && setActiveUserId(userId);

	// * User actions
	const toGroup = () => {
		navigate(`${routerPath.vacation.page}${routerPath.vacation.group}/${groupId}`);
	};

	if (!groupInfo) {
		return null;
	}

	// * Render
	return (
		<>
			{modalSendEmail && (
				<SendMailModal
					modalSendEmail={modalSendEmail}
					toggleModalSendEmail={toggleModalSendEmail}
				/>
			)}

			<div className={s.container}>
				{selectedDate && (
					<DatePickerTitleButton
						prefix={groupInfo.name}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						format="year"
						size="large"
					/>
				)}

				<div>
					<Button onClick={openModal}>Внести планируемый отпуск</Button>

					<DropDownMenu // TODO
						items={[
							{
								title: 'Настроить группу',
								action: toGroup,
							},
							{
								title: 'Отправить e-mail',
								action: toggleModalSendEmail,
							},
							{
								title: 'Выгрузить в Excel',
								action: () => console.log('Выгрузить в Excel'),
							},
							{
								title: 'Утвердить план',
								action: () => console.log('Утвердить план'),
							},
						]}
					/>
				</div>
			</div>
		</>
	);
};
