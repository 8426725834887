import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { FC, useMemo, useState } from 'react';
import { ReactComponent as DownArrowSVG } from 'src/shared/assets/svg_icons/arrows/down_2.svg';
import { ReactComponent as UpArrowSVG } from 'src/shared/assets/svg_icons/arrows/up_2.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { UsersTableDataItem } from '../Users/consts/COLUMNS_USERS';
import s from './CardTable.module.scss';

interface Props {
	columns: ColumnDef<any, any>[];
	data: UsersTableDataItem[];
	onRowClick?: (rowId: number) => void;
	sortingColumnId?: string;
}

export const CardTable: FC<Props> = props => {
	const {
		columns, //
		data,
		onRowClick,
		sortingColumnId,
	} = props;

	// * Rights
	const isAdmin = useRights();

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	const users = useMemo(() => {
		return isAscendingSort ? data : [...data].reverse();
	}, [data, isAscendingSort]);

	const table = useReactTable({
		data: users,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Style
	const gridStyle = isAdmin ? `48px repeat(${columns.length - 2}, 1fr) 24px` : `48px repeat(${columns.length - 1}, 1fr)`;

	//* Render
	return (
		<div className={s.container}>
			<div className={s.table}>
				{table.getHeaderGroups().map(headerGroup => (
					<div
						key={headerGroup.id}
						style={{ gridTemplateColumns: gridStyle }}
						className={cn(s.table_row, s.header_wrapper)}
					>
						{headerGroup.headers.map(header => (
							<div
								className={cn(s.table_header, { [s.sort_wrapper]: !!sortingColumnId })}
								key={header.id}
								onClick={header.id === sortingColumnId ? handleSortChange : undefined}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								{header.id === sortingColumnId && (isAscendingSort ? <UpArrowSVG /> : <DownArrowSVG />)}
							</div>
						))}
					</div>
				))}

				{table.getRowModel().rows.map(row => (
					<div
						key={row.id}
						style={{ gridTemplateColumns: gridStyle }}
						className={cn(s.table_row, s.cell_card)}
						onClick={event => {
							if (onRowClick) {
								event.stopPropagation();
								onRowClick(+row.id);
							}
						}}
					>
						{row.getVisibleCells().map(cell => (
							<div
								className={s.table_cell}
								key={cell.id}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
