import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubgroupResponse } from 'src/app/redux/queries/vacation-service/types/resTypes';
import {
	useDeleteGroupsByGroupIdSubgroupsAndSubgroupIdMutation,
	usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation,
	usePostGroupsByGroupIdSubgroupsMutation,
} from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { getGroupData } from '../../../../lib/getGroupData';
import { AddSubGroupMC } from '../../../AddEditSubGroupMC';
import { SubGroupCard } from '../../../SubGroupCard';
import s from '../../styles/Content.module.scss';

interface Props {
	data: SubgroupResponse[];
}

export const TabCategories: React.FC<Props> = ({ data }) => {
	const categories = getGroupData(data);
	const { groupId } = useParams() as { groupId: string };

	// * Modals
	const [isOpenForm, setIsOpenForm] = useState(false);
	const toggleFormModal = () => setIsOpenForm(prevState => !prevState);

	// * API
	const [createCategory, { isLoading: createLoading }] = usePostGroupsByGroupIdSubgroupsMutation();
	const [editCategory, { isLoading: editLoading }] = usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation();
	const [deleteCategory, { isLoading: deleteLoading }] = useDeleteGroupsByGroupIdSubgroupsAndSubgroupIdMutation();

	// * Actions
	const onAdd = (data: { name: string }) => {
		createCategory({
			groupId: groupId,
			subgroupBody: {
				name: data.name,
				type: 'Category',
			},
		})
			.unwrap()
			.then(() => toggleFormModal())
			.catch(e => console.log(e));
	};

	const onEdit = (id: string, name: string) => {
		editCategory({
			groupId: groupId,
			subgroupId: id,
			body: [
				{
					op: 'replace',
					value: name,
					path: '/Name',
				},
			],
		})
			.unwrap()
			.catch(e => console.log(e));
	};

	const onDelete = (id: string) => {
		deleteCategory({
			groupId,
			subgroupId: id,
		})
			.unwrap()
			.catch(e => console.log(e));
	};

	// * Render
	return (
		<>
			{isOpenForm && (
				<ModalNewProto
					isOpen={isOpenForm}
					onClose={toggleFormModal}
					width="s"
				>
					<AddSubGroupMC
						type="Category"
						closeModal={toggleFormModal}
						onSubmitAction={onAdd}
						isLoading={createLoading}
					/>
				</ModalNewProto>
			)}

			<div className={s.tab_container}>
				<AddButton
					title="Добавить категорию"
					onClick={toggleFormModal}
					className={s.add_button}
				/>

				{categories.map(category => (
					<SubGroupCard
						key={category.id}
						{...category}
						type="Category"
						editAction={onEdit}
						deleteAction={onDelete}
						isLoading={editLoading || deleteLoading}
					/>
				))}
			</div>
		</>
	);
};
