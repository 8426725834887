import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ReactNode, useMemo, useState } from 'react';
import { ReactComponent as DownArrowSVG } from 'src/shared/assets/svg_icons/arrows/down_2.svg';
import { ReactComponent as UpArrowSVG } from 'src/shared/assets/svg_icons/arrows/up_2.svg';
import { COLUMNS_SENDERS } from './consts';
import s from './StatisticsTable.module.scss';

export interface TABLE_DATA {
	id: string;
	fullname: ReactNode;
	done: number | string;
	onTime: string | number;
	notOnTime: string | number;
	rejected: number | string;
	inWork: number | string;
}

export const StatisticsTable = () => {
	const data: TABLE_DATA[] = [];

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	const sortedData = useMemo(() => {
		return isAscendingSort ? data : [...data].reverse();
	}, [data, isAscendingSort]);

	const table = useReactTable({
		data: sortedData,
		columns: COLUMNS_SENDERS,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Render
	return (
		<div>
			<div className={s.table}>
				{table.getHeaderGroups().map(item => (
					<div
						key={item.id}
						className={s.table_column}
					>
						{item.headers.map(item => (
							<div
								key={item.id}
								onClick={item.id === 'fullname' ? handleSortChange : undefined}
							>
								<div className={s.sort_wrapper}>
									{item.isPlaceholder ? null : flexRender(item.column.columnDef.header, item.getContext())}
									{item.id === 'fullname' && (isAscendingSort ? <UpArrowSVG /> : <DownArrowSVG />)}
								</div>
							</div>
						))}
					</div>
				))}
				{/* {data  && table.getRowModel().rows.map(row => (
					<div
						key={row.id}
						className={cn(s.table_row, s.cell_card)}
					>
						{row.getVisibleCells().map(cell => (
							<div key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
						))}
					</div>
				))} */}
			</div>
		</div>
	);
};
