import { DateTime, DayNumbers } from 'luxon';
import { calculateWorkDaysInMonth } from '../calculateWorkDaysInMonth/calculateWorkDaysInMonth';
import { findMonthHolidays } from '../findMonthHolidays/findMonthHolidays';
import { createArrayWithBetweenNumbers } from '../../array/createArrayWithBetweenNumbers/createArrayWithBetweenNumbers';

export interface MonthHolidays {
	month: number;
	year: number;
	holidays: string; // Бинарная строка: 0 - рабочий день, 1 - выходной день/праздник
}

export const calculateWorkDays = (startTime: Date | null, endTime: Date | null, allMonthHolidays: MonthHolidays[]) => {
	const start = startTime && DateTime.fromJSDate(startTime);
	const end = endTime && DateTime.fromJSDate(endTime);

	let workDays = 0;

	if (start && end) {
		const selectedMonths = [
			{
				month: start.month,
				year: start.year,
			},
			{
				month: end.month,
				year: end.year,
			},
		];

		if (selectedMonths[0].month === selectedMonths[1].month && selectedMonths[0].year === selectedMonths[1].year) {
			workDays = calculateWorkDaysInMonth({
				startDay: start.day,
				endDay: end.day,
				holidays: findMonthHolidays(selectedMonths[0], allMonthHolidays),
			});
		} else {
			// Выбрано более одного месяца
			const firstMonthWorkDays = calculateWorkDaysInMonth({
				startDay: start.day,
				endDay: start.daysInMonth as number,
				holidays: findMonthHolidays(selectedMonths[0], allMonthHolidays),
			});

			const lastMonthWorkDays = calculateWorkDaysInMonth({
				startDay: 1,
				endDay: end.day,
				holidays: findMonthHolidays(selectedMonths[selectedMonths.length - 1], allMonthHolidays),
			});

			const betweenMonths = createArrayWithBetweenNumbers(start.month, end.month);
			const betweenMonthsWorkDays = betweenMonths.reduce((sum, value) => {
				const date = DateTime.fromObject({ month: value, year: start.year });
				return (
					sum +
					calculateWorkDaysInMonth({
						startDay: 1,
						endDay: date.daysInMonth as DayNumbers,
						holidays: findMonthHolidays(date, allMonthHolidays),
					})
				);
			}, 0);

			workDays = firstMonthWorkDays + lastMonthWorkDays + betweenMonthsWorkDays;
		}
	}

	return workDays;
};
