import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useGetUserGetQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { useGetVacationsQuery, usePostVacationsMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { calculatePureHolidays, calculateWorkDays, filterHolidaysByBinaryString, generateDatesByIsoInterval, getDaysSum } from 'src/shared/lib/date';
import { MonthHolidays } from 'src/shared/lib/date/calculateWorkDays/calculateWorkDays';
import { Button } from 'src/shared/ui/_buttons/Button';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { DateRangeField } from 'src/shared/ui/_fields/DateRangeField';
import { Heading } from 'src/shared/ui/Heading';
import { ValidationRequirements } from 'src/shared/ui/ValidationRequirements';
import { useGetSelectedYear } from '../../VacationTable/ui/VacationTable/useGetSelectedYear';
import s from './PlannedVacationMC.module.scss';
import { useGroupUserContext } from '../../../context/useGroupUsersContext';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';

const VACATION_FIELD_NAME = 'vacations';

export interface FormValues {
	vacations: Array<{
		startDate: Date;
		endDate: Date;
	}>;
}
interface Props {
	userId: string;
	toggleModal: () => void;
}

export const PlannedVacationMC: FC<Props> = props => {
	const {
		userId, //
		toggleModal,
	} = props;

	// * Context
	const { users } = useGroupUserContext();

	// * Navigation
	const [searchParams] = useSearchParams();
	// const groupId = searchParams.get('group');
	const year = searchParams.get('year');

	const minDate = `${year}-01-01`;
	const maxDate = `${year}-12-31`;

	// // * Context
	// const { users, isLoading } = useGroupUserContext();

	// * API
	const selectedYear = useGetSelectedYear(Number(year));

	const {
		data: vacationData,
		isLoading: vacationIsLoading,
		isSuccess,
	} = useGetVacationsQuery(
		{
			startTime: minDate,
			endTime: maxDate,
			// groupId: groupId ?? undefined,
			userId,
		},
		{ skip: !userId },
	);
	// usePostVacationsMutation

	const { data: userData, isLoading: userIsLoading } = useGetUserGetQuery({
		userid: userId,
		includecurrentavatar: false,
		includeavatars: false,
		includecover: false,
		includecommunications: false,
		includecompany: false,
		includedepartment: false,
		includeoffice: false,
		includeposition: false,
		includerole: false,
	});

	const [createVacation, { isLoading: createVacationIsLoading }] = usePostVacationsMutation();

	const isLoading = vacationIsLoading || userIsLoading || createVacationIsLoading;

	// * Form
	// const user = users.find(user => user.id === userId);

	let allMonthHolidays: MonthHolidays[] = [];
	let highlightedDates: Date[] = []; // Список праздничных дат в выбранном году.

	if (selectedYear) {
		allMonthHolidays = selectedYear.months.map((month, index) => ({
			holidays: month.holidays,
			month: index + 1,
			year: selectedYear.year,
		}));

		highlightedDates = filterHolidaysByBinaryString(allMonthHolidays);
	}

	const formMethods = useForm<FormValues>({
		// resolver: yupResolver(
		// 	yup.object().shape({
		// 		vacations: yup.array().of(
		// 			yup.object().shape({
		// 				startDate: yup.date().nullable().required('Добавьте дату начала'),
		// 				endDate: yup.date().nullable().required('Добавьте дату окончания'),
		// 			}),
		// 		),
		// 	}),
		// ),
	});

	const { control } = formMethods;

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: VACATION_FIELD_NAME,
	});

	const newField = { startDate: new Date(), endDate: new Date() };

	useEffect(() => {
		if (isSuccess && vacationData) {
			const vacations = vacationData.body;

			if (vacations?.length === 0 && fields.length === 0) {
				append(newField);
			}

			if (vacations && vacations?.length > 0) {
				// TODO: Заполнить форму.
			}
		}
	}, [vacationData]);

	const { handleSubmit, watch, setError, formState, clearErrors } = formMethods;

	const formValues = watch();

	const onSubmit = (values: FormValues) => {
		const vacationTimes = values.vacations.map(({ startDate, endDate }) => ({
			startTime: DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd'),
			endTime: DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd'),
		}));

		if (validations.total && validations.atLeast) {
			createVacation({
				vacationRequest: {
					userId: userId,
					vacationType: 'Planned',
					vacationTimes,
				},
			});
		}
	};

	const onFieldAdd = () => {
		append(newField);
	};

	const onFieldRemove = (index: number, totalFields: number) => {
		if (totalFields > 1) {
			remove(index);
		}
	};

	const vacations = formValues.vacations ?? [];

	const fieldsInfo = vacations.map(({ startDate, endDate }) => {
		const daysSum = endDate ? getDaysSum(startDate, endDate) + 1 : 0;
		// const holidaysSum = daysSum - calculateWorkDays(startDate, endDate, allMonthHolidays);
		const holidaysSum = calculatePureHolidays(startDate, endDate, allMonthHolidays);

		return {
			daysSum,
			holidaysSum,
			startDateISO: DateTime.fromJSDate(startDate).toISO() as string,
			endDateISO: DateTime.fromJSDate(endDate).toISO() as string,
		};
	});

	const allFieldsSelectedDates = fieldsInfo.map(({ startDateISO, endDateISO }) => generateDatesByIsoInterval(startDateISO, endDateISO));
	const totalDaysSelected = fieldsInfo.reduce((total, sums) => (total += sums.daysSum), 0);
	const availableVacationDays = users.find(user => user.id === userId)?.days.total ?? 0;

	const [validations, setValidation] = useState<{ total: boolean; atLeast: boolean }>({ total: false, atLeast: false });

	useEffect(() => {
		const atLeast = fieldsInfo.some(sum => sum.daysSum >= 14);
		const total = totalDaysSelected <= availableVacationDays;

		if (validations.atLeast !== atLeast || validations.total !== total) {
			setValidation({ total, atLeast });
		}
	}, [totalDaysSelected, fieldsInfo]);

	// * Render
	return (
		<div className={s.container}>
			<Heading
				className={s.heading}
				level={2}
				marginBottom="l"
			>
				Внести
				<br /> планируемый отпуск
			</Heading>

			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ValidationRequirements
						className={s.requirements}
						requirements={[
							{
								text: 'Количество внесённых дней отпуска не может превышать количество доступных дней отпуска',
								isDone: validations.total,
							},
							{
								text: 'Хотя бы один из интервалов отпуска должен быть не менее 14 дней',
								isDone: validations.atLeast,
							},
						]}
					/>

					<div className={s.available}>
						<div>
							<div className={s.available__label}>Доступно дней отпуска</div>
							<div className={s.available__value}>{availableVacationDays}</div>
						</div>

						<div>
							<div className={s.available__label}>Внесено дней отпуска</div>
							<div className={s.available__value}>{totalDaysSelected}</div>
						</div>
					</div>

					<div className={s.fields}>
						{fields.map(({ id }, index, arr) => {
							const daysSum = fieldsInfo[index].daysSum;
							const holidaysSum = fieldsInfo[index].holidaysSum;

							return (
								<div key={id}>
									<span className={s.interval__header}>
										<span className={s.interval__title}>{index + 1} интервал отпуска</span>
										<IconButton
											Icon={<DeleteSVG />}
											onClick={() => onFieldRemove(index, arr.length)}
										/>
									</span>

									<DateRangeField
										className={s.interval__fields}
										labels={['Дата начала', 'Дата окончания']}
										startTimeName={`${VACATION_FIELD_NAME}.${index}.startDate`}
										endTimeName={`${VACATION_FIELD_NAME}.${index}.endDate`}
										minDate={DateTime.fromFormat(minDate, 'yyyy-MM-dd').toJSDate()}
										maxDate={DateTime.fromFormat(maxDate, 'yyyy-MM-dd').toJSDate()}
										highlightedDates={highlightedDates}
										excludeDates={allFieldsSelectedDates.filter((_, dateIndex) => dateIndex !== index).flat()}
									/>

									<span className={s.interval__description}>
										Выбрано дней: {daysSum}, в том числе {holidaysSum} {holidaysSum === 1 ? 'праздничный день, который' : 'праздничных дней, которые'} не оплачивается.
									</span>
								</div>
							);
						})}
					</div>

					<Button
						className={s.interval__addButton}
						type="button"
						variant="secondary"
						onClick={onFieldAdd}
						wide
					>
						Добавить интервал отпуска
					</Button>

					<ButtonPair
						primaryText="Сохранить"
						secondaryText="Отмена"
						primaryIsLoading={isLoading}
						secondaryIsLoading={isLoading}
						secondaryOnClick={toggleModal}
					/>
				</form>
			</FormProvider>
		</div>
	);
};
