import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { GroupUsersContextProvider } from '../../context/useGroupUsersContext';
import { useGetGroupUsers } from '../../hooks/useGetGroupUsers';
import { PlannedVacationMC } from '../_modals/PlannedVacationMC';
import { Filters } from '../Filters';
import { Groups } from '../Groups';
import { Header } from '../Header';
import { SubHeader } from '../SubHeader';
import { VacationTable } from '../VacationTable';
import { User } from '../../types';

export const VacationPage: FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const localStorageYear = localStorage.getItem('vacationYear');
	const localStorageGroup = localStorage.getItem('vacationGroupId');

	useEffect(() => {
		setSearchParams({
			group: localStorageGroup || 'null',
			year: localStorageYear || String(new Date().getFullYear().toString()),
		});
	}, []);

	const groupId = searchParams.get('group');

	const { users, isLoading } = useGetGroupUsers(groupId);

	// * Planned vacation modal
	const [activeUserId, setActiveUserId] = useState<string | null>(null);
	const closeModal = () => setActiveUserId(null);

	// * Filters
	const [nameFilter, setNameFilter] = useState<string>('');
	const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

	useEffect(() => {
		if (nameFilter === '') {
			setFilteredUsers(users);
		} else {
			setFilteredUsers(users.filter(user => user.name.toLocaleLowerCase().includes(nameFilter)));
		}
	}, [users.length, nameFilter]);

	// * Render
	return (
		<PageDecorator>
			<GroupUsersContextProvider
				users={filteredUsers}
				isLoading={isLoading}
				activeUserId={activeUserId}
				setActiveUserId={setActiveUserId}
				nameFilter={nameFilter}
			>
				{activeUserId && (
					<ModalNewProto
						isOpen={!!activeUserId}
						onClose={closeModal}
						width="s"
					>
						<PlannedVacationMC
							userId={activeUserId}
							toggleModal={closeModal}
						/>
					</ModalNewProto>
				)}
				<Header />
				<Groups />
				<SubHeader />
				<Filters
					name={nameFilter}
					setName={setNameFilter}
				/>
				<VacationTable />
			</GroupUsersContextProvider>
		</PageDecorator>
	);
};
