import { createContext, FC, ReactNode, useContext } from 'react';
import { User } from '../types';

interface Context {
	users: User[];
	isLoading: boolean;
	activeUserId: string | null;
	setActiveUserId: (userId: string | null) => void;
	nameFilter: string;
}

interface Props extends Context {
	children: ReactNode;
}

const GroupUsersContext = createContext<Context | null>(null);

export const GroupUsersContextProvider: FC<Props> = props => {
	const {
		users,
		isLoading,
		activeUserId,
		setActiveUserId,
		nameFilter,

		children,
	} = props;

	// * Render
	return (
		<GroupUsersContext.Provider
			value={{
				users,
				isLoading,
				activeUserId,
				setActiveUserId,
				nameFilter,
			}}
		>
			{children}
		</GroupUsersContext.Provider>
	);
};

export const useGroupUserContext = () => {
	const context = useContext(GroupUsersContext);

	if (!context) {
		throw new Error('useWorkTimesInfoContext must be used within WorkTimesInfoContext');
	}

	return context;
};
