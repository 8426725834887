import { User } from '../../../../../types';

export function getGroupsDisplay(users: User[]) {
	let displayCategory = false;
	let displayGroup = false;
	let displayTeam = false;

	users.forEach(user => {
		if (user.category !== null) {
			displayCategory = true;
		}
		if (user.group !== null) {
			displayGroup = true;
		}
		if (user.team !== null) {
			displayTeam = true;
		}
	});

	return {
		displayCategory,
		displayGroup,
		displayTeam,
	};
}
