export interface Args {
	startDay: number;
	endDay: number;
	holidays?: string; // Бинарная строка: 0 - рабочий день, 1 - выходной день/праздник
}

export const calculateWorkDaysInMonth = (args: Args) => {
	const { startDay, endDay, holidays } = args;

	if (holidays) {
		const selectedDaysBinaryArray = holidays.slice(startDay - 1, endDay).split('');
		return selectedDaysBinaryArray.reduce((sum, value) => (value === '0' ? sum + 1 : sum), 0);
	} else {
		return 0;
	}
};
