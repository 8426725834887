import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { ReactComponent as DownArrowSVG } from 'src/shared/assets/svg_icons/arrows/down_2.svg';
import { ReactComponent as UpArrowSVG } from 'src/shared/assets/svg_icons/arrows/up_2.svg';
import { COLUMNS, ParticipantsData } from '../consts';
import s from './ParticipantsTable.module.scss';

interface Props {
	data: ParticipantsData[];
}

export const ParticipantsTable: React.FC<Props> = ({ data }) => {
	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	const sortedData = useMemo(() => {
		return isAscendingSort ? data : [...data].reverse();
	}, [data, isAscendingSort]);

	const Table = useReactTable({
		data: sortedData,
		columns: COLUMNS,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Render
	return (
		<div className={s.table}>
			{Table.getHeaderGroups().map(headerGroup => (
				<div
					key={headerGroup.id}
					className={s.table_row}
				>
					{headerGroup.headers.map(header => (
						<div
							key={header.id}
							className={cn(s.table_header, s.sort_wrapper)}
							onClick={header.id === 'name' ? handleSortChange : undefined}
						>
							{flexRender(header.column.columnDef.header, header.getContext())}
							{header.id === 'name' && (isAscendingSort ? <UpArrowSVG /> : <DownArrowSVG />)}
						</div>
					))}
				</div>
			))}

			{Table.getRowModel().rows.map(row => (
				<div
					key={row.id}
					className={cn(s.table_row, s.table_card)}
				>
					{row.getVisibleCells().map(cell => (
						<div key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
					))}
				</div>
			))}
		</div>
	);
};
