import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import s from './Filters.module.scss';
import { FC } from 'react';

interface Props {
	name: string;
	setName: (name: string) => void;
}

export const Filters: FC<Props> = props => {
	const {
		name, //
		setName,
	} = props;

	// * Render
	return (
		<div className={s.container}>
			<TextInput // TODO
				value={name}
				placeholder="Поиск по ФИО"
				onChange={event => setName(event.currentTarget.value)}
				RightIcon={<SearchSVG />}
			/>

			<SelectSingle // TODO
				placeholder="Статус"
				keyNames={{
					name: 'name',
					value: 'value',
				}}
				options={[{ name: 'Статус: все', value: 'all' }]}
				selectedOption={{ name: 'Статус: все', value: 'all' }}
				setSelectedOption={value => console.log(value)}
			/>
		</div>
	);
};
