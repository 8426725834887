import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { useGroupUserContext } from '../../../../context/useGroupUsersContext';
import { CELL_GAP, DIVIDER_WIDTH } from '../../consts';
import { SortByState } from '../../types';
import { MonthSelector } from '../MonthSelector';
import { ScrollbarWrapper } from '../ScrollbarWrapper';
import { Table } from '../Table/ui/Table/Table';
import { UsersTable } from '../UsersTable';
import { getGroupsDisplay } from './lib/getGroupsDisplay';
import { getUserColumns } from './lib/getUserColumns';
import { sortUsers } from './lib/sortUsers';
import { useGetSelectedYear } from './useGetSelectedYear';
import s from './VacationTable.module.scss';

export const VacationTable: React.FC = () => {
	// * Routing
	const [searchParams] = useSearchParams();

	const year = searchParams.get('year');

	// * Context
	const { users, isLoading, nameFilter } = useGroupUserContext();

	// * Columns
	const groupsDisplay = getGroupsDisplay(users);
	const columns = getUserColumns(groupsDisplay);

	// * Sorting
	const [sortBy, setSortBy] = useState<SortByState>(null);
	const sortedUsers = sortUsers(users, sortBy);

	// * Year
	const selectedYear = useGetSelectedYear(Number(year));

	// * Table
	const leftSideWidth = columns.reduce((sum, current) => (sum += current.width + CELL_GAP), 0) + DIVIDER_WIDTH; // TODO: Заменить вычисления на реальное количество колонок.
	const tableRef = useRef<HTMLDivElement>(null);

	// * Render
	if (!selectedYear || isLoading) {
		return <LoaderCircle />;
	}

	if (users.length === 0 && !isLoading && nameFilter) {
		return <span>Сотрудников не найдено</span>;
	}

	return (
		<div className={s.container}>
			<ScrollbarWrapper
				usersTableWidth={leftSideWidth}
				usersCount={sortedUsers.length}
				ref={tableRef}
			>
				<div className={s.table}>
					<UsersTable
						users={sortedUsers}
						columns={columns}
						sortBy={sortBy}
						setSortBy={setSortBy}
					/>

					<Table
						users={sortedUsers}
						selectedYear={selectedYear}
					/>
				</div>
			</ScrollbarWrapper>

			<MonthSelector
				width={`calc(100% - ${leftSideWidth}rem)`}
				selectedYear={selectedYear}
				tableRef={tableRef}
			/>
		</div>
	);
};
