import { DateTime } from 'luxon';
import { createArrayWithBetweenNumbers } from '../../array';
import { MonthHolidays } from '../calculateWorkDays/calculateWorkDays';
import { generateDatesByIsoInterval } from '../generateDatesByIsoInterval/generateDatesByIsoInterval';

const calculatePureHolidaysInMonth = (dates: Date[], holidayBinaryArray: string[]) => {
	let holidays = 0;

	dates.forEach((date, index) => {
		const dateTime = DateTime.fromJSDate(date);
		const binaryValue = holidayBinaryArray[index];

		if (binaryValue === '1' && !dateTime.isWeekend) {
			holidays += 1;
		}
	});

	return holidays;
};

// Возвращает число праздничных дней. Выходные не включает выходные дни.
export const calculatePureHolidays = (startTime: Date, endTime: Date, allMonthHolidays: MonthHolidays[]) => {
	const startDataTime = DateTime.fromJSDate(startTime);
	const endDateTime = DateTime.fromJSDate(endTime);

	const startDateISO = startDataTime.toISO() as string;
	const endDateISO = endDateTime.toISO() as string;

	let allDates: Date[] = [];

	allDates = generateDatesByIsoInterval(startDateISO, endDateISO);

	let holidays = 0;

	const selectedMonths = [
		{
			month: startDataTime.month,
			year: startDataTime.year,
		},
		{
			month: endDateTime.month,
			year: endDateTime.year,
		},
	];

	if (selectedMonths[0].month === selectedMonths[1].month && selectedMonths[0].year === selectedMonths[1].year) {
		const monthHolidayBinary = allMonthHolidays[selectedMonths[0].month - 1].holidays;
		const selectedDaysBinaryArray = monthHolidayBinary.slice(startDataTime.day - 1, endDateTime.day).split('');

		holidays += calculatePureHolidaysInMonth(allDates, selectedDaysBinaryArray);
	} else {
		// Выбрано более одного месяца
		const firstMonthHolidayBinary = allMonthHolidays[selectedMonths[0].month - 1].holidays;
		const lastMonthHolidayBinary = allMonthHolidays[selectedMonths[1].month - 1].holidays;

		const firstMonthHolidayBinaryArray = firstMonthHolidayBinary.slice(startDataTime.day - 1, startDataTime.daysInMonth).split('');
		const lastMonthHolidayBinaryArray = lastMonthHolidayBinary.slice(0, endDateTime.day).split('');

		const firstMonthDates = generateDatesByIsoInterval(startDateISO, startDataTime.endOf('month').toISO() as string);
		const lastMonthDates = generateDatesByIsoInterval(endDateTime.startOf('month').toISO() as string, endDateISO);

		holidays += calculatePureHolidaysInMonth(firstMonthDates, firstMonthHolidayBinaryArray);
		holidays += calculatePureHolidaysInMonth(lastMonthDates, lastMonthHolidayBinaryArray);

		const betweenMonthsInfo = createArrayWithBetweenNumbers(startDataTime.month, endDateTime.month);
		betweenMonthsInfo.forEach(month => {
			const monthHolidayBinary = allMonthHolidays[month - 1].holidays;
			const monthHolidayBinaryArray = monthHolidayBinary.split('');
			const date = DateTime.fromObject({ month, year: startDataTime.year });
			const monthDates = generateDatesByIsoInterval(date.startOf('month').toISO() as string, date.endOf('month').toISO() as string);

			holidays += calculatePureHolidaysInMonth(monthDates, monthHolidayBinaryArray);
		});
	}

	return holidays;
};
