import { useEffect, useState } from 'react';
import { useLazyGetUserGetQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { UserResponse } from 'src/app/redux/queries/vacation-service/types/resTypes';
import { useGetGroupsByGroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { getDaysSum } from 'src/shared/lib/date';
import { createFullName } from 'src/shared/lib/string';
import { SubGroupType } from '../../GroupPage/types';
import { User } from '../types';

type UserWithSubgroup = UserResponse & { group?: { type: SubGroupType; name: string } };

export const useGetGroupUsers = (groupId: string | null) => {
	// * API
	const { data: groupData } = useGetGroupsByGroupIdQuery(
		{
			groupId: groupId ?? '',
		},
		{ skip: groupId === null },
	);

	const group = groupData?.body;

	const [getUser, { isLoading }] = useLazyGetUserGetQuery();

	// * Users
	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		const subGroupUsers: UserWithSubgroup[] = (group?.subgroups ?? [])
			.map(subGroup => {
				return subGroup.users
					? subGroup.users.map(user => ({
							...user,
							group: {
								name: subGroup.name,
								type: subGroup.type,
							},
							vacations: group?.users?.find(groupUser => groupUser.id === user.id)?.vacations ?? [],
						}))
					: [];
			})
			.flat();

		const groupUsers: UserWithSubgroup[] = group?.users?.filter(groupUser => !subGroupUsers.find(subGroupUser => subGroupUser.id === groupUser.id)) ?? [];

		const allUsers = [...groupUsers, ...subGroupUsers];

		const promises: ReturnType<typeof getUser>[] = [];

		if (allUsers.length > 0) {
			allUsers.forEach(user => {
				promises.push(
					getUser({
						userid: user.id,
						includecurrentavatar: false,
						includeavatars: false,
						includecover: false,
						includecommunications: false,
						includecompany: false,
						includedepartment: false,
						includeoffice: false,
						includeposition: false,
						includerole: false,
					}),
				);
			});
		}

		Promise.all(promises)
			.then(res => {
				const tableUsers: User[] = [];

				res.forEach((resItem, index) => {
					const resUser = resItem.data?.body?.user;
					const groupUser = allUsers[index];

					const left = groupUser.body.remainingVacationDays; // Остатки отпуска с прошлых лет.
					const total = resItem.data?.body?.userAddition?.entitledVacationDays ?? 0 + left; // Сумма отпусков в год. TODO: Возможно лучше показывать ошибку вместо 0
					const used = groupUser.vacations //
						? groupUser.vacations.reduce((acc, value) => {
								const time = value.vacationBody?.time;
								let vacationTime = 0;
								if (time && time.endTime && time.startTime) {
									vacationTime = getDaysSum(new Date(time.startTime), new Date(time.endTime));
								}

								return acc + vacationTime;
							}, 0)
						: 0;

					if (resUser) {
						const user: User = {
							id: resUser.id,
							name: createFullName({
								lastName: resUser.lastName ?? '',
								middleName: resUser.middleName ?? '',
								firstName: resUser.firstName ?? '',
							}),
							category: groupUser.group?.type === 'Category' ? groupUser.group.name : null,
							group: groupUser.group?.type === 'Group' ? groupUser.group.name : null,
							team: groupUser.group?.type === 'Team' ? groupUser.group.name : null,
							days: {
								total,
								left,
								used,
							},
							vacations: groupUser.vacations?.map(({ id, vacationBody }) => ({
								id: id as string,
								title: 'Планируемый отпуск',
								startDate: vacationBody?.time?.startTime as string,
								endDate: vacationBody?.time?.endTime as string,
								type: 'Planned',
							})),
						};

						tableUsers.push(user);
					}
				});

				setUsers(tableUsers);
			})
			.catch(error => console.log('error', error));
	}, [group]);

	return {
		users,
		isLoading,
	};
};
