import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DateRangeInput } from 'src/shared/ui/_inputs/date_pickers/DateRangeInput';
import { ReactDatePickerProps } from 'react-datepicker';
import { extractNestedError } from 'src/shared/lib/fields';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'highlightDates'> {
	labels?: string[];
	startTimeName: string;
	endTimeName: string;

	startDatePlaceholder?: string;
	endDatePlaceholder?: string;
	disabledStart?: boolean;
	disabledEnd?: boolean;
	startRequired?: boolean;
	endRequired?: boolean;
	highlightedDates?: Date[];
}

export const DateRangeField: React.FC<Props> = props => {
	const {
		labels,

		startTimeName,
		endTimeName,

		startDatePlaceholder,
		endDatePlaceholder,
		disabledStart,
		disabledEnd,

		isClearable,
		highlightedDates,

		minDate,
		maxDate,
		excludeDates,
		excludeDateIntervals,
		startRequired,
		endRequired,
		...datePickerProps
	} = props;

	const { control, setValue } = useFormContext();

	// * Render
	return (
		<Controller
			name={startTimeName}
			control={control}
			render={({ field: startField, fieldState: startFieldState }) => (
				<Controller
					name={endTimeName}
					control={control}
					render={({ field: endField, fieldState: endFieldState }) => (
						<DateRangeInput
							{...datePickerProps}
							labels={labels}
							startDate={startField.value}
							setStartDate={date => setValue(startField.name, date)}
							disabledStart={disabledStart}
							disabledEnd={disabledEnd}
							endDate={endField.value}
							setEndDate={date => setValue(endField.name, date)}
							startDatePlaceholder={startDatePlaceholder}
							endDatePlaceholder={endDatePlaceholder}
							highlightDates={highlightedDates}
							isClearable={isClearable}
							minDate={minDate}
							maxDate={maxDate}
							excludeDates={excludeDates}
							excludeDateIntervals={excludeDateIntervals}
							errorMessage={extractNestedError(startFieldState.error || endFieldState.error)}
							startRequired={startRequired}
							endRequired={endRequired}
						/>
					)}
				/>
			)}
		/>
	);
};
