import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import {
	useGetGroupsByGroupIdQuery,
	useGetGroupsByGroupIdSubgroupsAndSubgroupIdQuery,
	usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation,
} from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';
import { createFullName } from 'src/shared/lib/string';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { Heading } from 'src/shared/ui/Heading';
import { SUB_GROUP } from '../../consts/consts';
import { SubGroupType } from '../../types';
import { AddUserContainer } from '../AddUserContainer/AddUserContainer';
import s from './SubGroupUsers.module.scss';
import { UserListItem } from 'src/app/redux/queries/vacation-service/types/types';
import { UsersList } from 'src/features/_card_lists/UsersList';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';

interface Props {
	type?: SubGroupType;
	name: string;
	subgroupId: string;
}

export const SubGroupUsers: FC<Props> = props => {
	const {
		type, //
		name,
		subgroupId,
	} = props;

	const { groupId } = useParams() as { groupId: string };

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * API
	const [editUsers, { isLoading: editLoading }] = usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation();

	const { data, isLoading: isLoadingGetUsers } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const { data: subGroupData, isLoading: isLoadingSubGroup } = useGetGroupsByGroupIdSubgroupsAndSubgroupIdQuery({
		groupId,
		subgroupId,
	});

	const { currentData: positionsData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
		isascendingsort: true,
	});

	const { data: groupData } = useGetGroupsByGroupIdQuery({
		groupId,
	});

	const allPositions = positionsData?.body || [];

	const groupUsersIds = groupData?.body?.users?.map(user => user.id);
	const subGroupsUserIds = subGroupData?.body?.users?.map(user => user.id) || [];
	const otherSubgroupsUserIds = groupData?.body?.subgroups
		?.filter(subGroup => subGroup.id !== subgroupId)
		.flat()
		.map(subGroup => subGroup.users)
		.flat()
		.map(user => user?.id);

	const allUsers =
		data?.body?.map(item => ({
			id: item.user.id,
			name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
			avatar: item.user.avatar,
			department: item.departmentUser?.department.name,
			position: '',
		})) || [];

	const allGroupUsers = groupUsersIds?.map(groupUserId => allUsers.find(user => user.id === groupUserId)).filter(item => item !== undefined); // Все пользователи в группе.
	const usersInSubgroup: UserListItem[] = []; // Пользователи находящиеся в данной группе.
	const usersNotInSubgroup: UserListItem[] = []; // Пользователи не находящиеся в какой либо группе.

	allGroupUsers?.forEach(user => {
		if (subGroupsUserIds.find(existingUserId => existingUserId === user?.id)) {
			usersInSubgroup.push(user as UserListItem);
		} else {
			// Фильтрация пользователей из других групп
			if (!otherSubgroupsUserIds?.find(otherSubgroupUserId => otherSubgroupUserId === user?.id)) {
				usersNotInSubgroup.push(user as UserListItem);
			}
		}
	});

	const onAddUsers = (userIds: string[]) => {
		// ! add не работает, пока не починят на бэке используем replace.
		editUsers({
			groupId,
			subgroupId: subgroupId,
			body: [
				{
					op: 'replace',
					value: [...usersInSubgroup.map(user => user.id), ...userIds],
					path: '/UserIds',
				},
			],
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(e => console.log(e));
	};

	const onDeleteUser = (userId: string) => {
		// ! remove не работает. Удаляет всех пользователей вместо одного, пока не починят на бэке используем replace.
		editUsers({
			groupId,
			subgroupId: subgroupId,
			body: [
				{
					op: 'replace',
					value: usersInSubgroup.filter(user => user.id !== userId).map(user => user.id),
					path: '/UserIds',
				},
			],
		});
	};

	// * Filters
	const [position, setPosition] = useState<SelectValue>({ id: '', name: '' });
	const [searchValue, setSearchValue] = useState('');

	// * Render
	return (
		<>
			{showModal && (
				<AddUserContainer
					showModal={showModal}
					toggleModal={toggleModal}
					editAction={onAddUsers}
					editLoading={editLoading || isLoadingSubGroup || isLoadingGetUsers}
					allUsers={usersNotInSubgroup}
				/>
			)}

			<div className={s.container}>
				<Heading level={2}>
					{type && SUB_GROUP[type]} <span className={s.subgroup_name}>{`“${name}”`}</span>
				</Heading>

				{isLoadingSubGroup || isLoadingGetUsers ? (
					<LoaderCircle />
				) : (
					<>
						<SelectStringSearchFilter
							selectPlaceholder="Должность"
							selectValue={position}
							setSelect={(value: SelectValue) => setPosition(value)}
							selectOptions={allPositions}
							searchPlaceholder="Введите ФИО сотрудника"
							searchStringValue={searchValue}
							setSearchString={setSearchValue}
						/>

						<div className={s.inner}>
							<SelectedCount
								title="Выбрано сотрудников"
								count={usersInSubgroup.length}
							/>

							<UsersList
								users={usersInSubgroup}
								onRemoveUser={id => onDeleteUser(id)}
								isLoading={editLoading}
							/>

							<AddButton
								title="Добавить сотрудников"
								onClick={toggleModal}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
};
