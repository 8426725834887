import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GroupResponse } from 'src/app/redux/queries/vacation-service/types/resTypes';
import { useDeleteGroupsByGroupIdMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { routerPath } from 'src/app/router/paths';
import { ConfirmModal } from 'src/entities/_modals/ConfirmModal/ConfirmModal';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { AddEditGroupMC } from '../../../VacationPage/ui/_modals/AddEditGroupMC';
import { SUB_GROUP } from '../AddEditSubGroupMC/consts';

interface Props {
	title: string;
	isCustom: boolean;
	group: GroupResponse;
}

export const Header: FC<Props> = props => {
	const {
		title, //
		isCustom,
		group,
	} = props;

	const navigate = useNavigate();

	// * Modals
	const [showEditModal, setShowEditModal] = useState(false);
	const toggleEditModal = () => setShowEditModal(prevSate => !prevSate);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const toggleDeleteModal = () => setShowDeleteModal(prevSate => !prevSate);

	// * API
	const [deleteGroup, { isLoading }] = useDeleteGroupsByGroupIdMutation();

	const onDelete = () => {
		deleteGroup({
			groupId: group.id,
		})
			.unwrap()
			.then(() => navigate(routerPath.vacation.page))
			.catch(e => console.log(e));
	};

	// * Render
	return (
		<>
			{showEditModal && (
				<ModalNewProto
					isOpen={showEditModal}
					onClose={toggleEditModal}
					width="s"
				>
					<AddEditGroupMC
						closeModal={toggleEditModal}
						group={group}
					/>
				</ModalNewProto>
			)}

			{showDeleteModal && (
				<ConfirmModal
					isOpen={showDeleteModal}
					onClose={toggleDeleteModal}
					title={SUB_GROUP.Group.delete}
					description={`${SUB_GROUP.Group.deleteInfo}\nОтменить это действие будет невозможно.`}
					primaryOnClick={onDelete}
					isLoading={isLoading}
				/>
			)}

			<PageTitle
				title={`${title}${isCustom ? ' (кастомная группа)' : ''}`}
				goBackRoute={{
					text: 'Планировщик отпусков',
					route: routerPath.vacation.page,
				}}
				buttons={[
					isCustom && (
						<DropDownMenu
							key={0}
							items={[
								{
									icon: <EditSVG />,
									title: 'Редактировать',
									action: () => toggleEditModal(),
								},
								{
									icon: <DeleteSVG />,
									title: 'Удалить',
									action: () => toggleDeleteModal(),
								},
							]}
						/>
					),
				]}
			/>
		</>
	);
};
