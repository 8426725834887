import { ComponentPropsWithoutRef, FC } from 'react';
import { Button } from 'src/shared/ui/_buttons/Button';
import s from './ButtonPair.module.scss';
import cn from 'classnames';

interface Props extends ComponentPropsWithoutRef<'div'> {
	primaryText: string;
	primaryOnClick?: () => void; // ! Если данный параметр отсутствует тога главная кнопа ведет себя как SUBMIT формы.
	primaryIsLoading: boolean;
	primaryDisabled?: boolean;
	secondaryText: string;
	secondaryOnClick: () => void;
	secondaryIsLoading: boolean;
}

export const ButtonPair: FC<Props> = props => {
	const {
		primaryText,
		primaryOnClick,
		primaryIsLoading,
		primaryDisabled,

		secondaryText,
		secondaryOnClick,
		secondaryIsLoading,
		className,
	} = props;

	return (
		<div className={cn(s.container, className)}>
			<Button
				type="button"
				variant="tertiary"
				onClick={secondaryOnClick}
				isLoading={secondaryIsLoading}
				fixedWidth
			>
				{secondaryText}
			</Button>

			<Button
				type={primaryOnClick ? 'button' : 'submit'}
				variant="primary"
				onClick={primaryOnClick}
				isLoading={primaryIsLoading}
				disabled={primaryDisabled}
				fixedWidth
			>
				{primaryText}
			</Button>
		</div>
	);
};
